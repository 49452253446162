export default {
  methods: {
    checkCIF(cif) {
      const v1 = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
      const tempStr = cif.toUpperCase(); // pasar a mayúsculas
      let temp = 0;

      // Comprueba el formato
      const regExp = new RegExp(this.CIF_regExp);
      if (!tempStr.match(regExp)) return false; // Valida el formato?
      if (!/^[ABCDEFGHJKLMNPQRSUVW]/.test(tempStr)) return false; // Es una letra de las admitidas ?

      for (let i = 2; i <= 6; i += 2) {
        temp += v1[Number(cif.substr(i - 1, 1))];
        temp += Number(cif.substr(i, 1));
      }
      temp += v1[Number(cif.substr(7, 1))];
      temp = 10 - (temp % 10);
      if (temp === 10) temp = 0;
      const dc = cif.toUpperCase().charAt(8);
      temp = String(temp);
      return (
        dc === temp ||
        (temp === '1' && dc === 'A') ||
        (temp === '2' && dc === 'B') ||
        (temp === '3' && dc === 'C') ||
        (temp === '4' && dc === 'D') ||
        (temp === '5' && dc === 'E') ||
        (temp === '6' && dc === 'F') ||
        (temp === '7' && dc === 'G') ||
        (temp === '8' && dc === 'H') ||
        (temp === '9' && dc === 'I') ||
        (temp === '0' && dc === 'J')
      );
    },
    checkNIF(nif) {
      let copyNIF = nif;
      // Comprueba la longitud. Los DNI antiguos tienen 7 digitos.
      if (copyNIF.length !== 8 && copyNIF.length !== 9) return false;
      if (copyNIF.length === 8) {
        copyNIF = `0${copyNIF}`;
      } // Ponemos un 0 a la izquierda y solucionado

      // COMPROBACION NUEVOS DNI CON LETRA AL PRINCIPIO
      if (['l', 'L', 'k', 'K', 'm', 'M'].includes(copyNIF[0])) {
        if (!nif.match(this.NIF_NUEVO_regExp)) return false;
        return true;
      }
      // Comprueba el formato
      const regExp = new RegExp(this.NIF_regExp);
      if (!nif.match(regExp)) return false;
      const word = copyNIF.charAt(copyNIF.length - 1);
      const dni = copyNIF.substring(0, copyNIF.length - 1);
      const letra = this.NIF_Letters.charAt(dni % 23);
      return letra === word.toUpperCase();
    },
    NIFValidator(identifier) {
      this.NIF_Letters = 'TRWAGMYFPDXBNJZSQVHLCKET';
      this.NIF_regExp = '^\\d{8}[a-zA-Z]{1}$';
      this.NIF_NUEVO_regExp = '^[LlKkMm]{1}\\d{7}[a-zA-Z]{1}$';
      this.CIF_regExp = '^[a-zA-Z]{1}\\d{7}[a-jA-J0-9]{1}$';
      if (this.checkCIF(identifier)) {
        // Comprueba el CIF
        return true;
      }

      if (this.checkNIF(identifier)) {
        // Comprueba el NIF
        return true;
      }
      return false;
    },
    NIEValidator(identifier) {
      // Change the initial letter for the corresponding number and validate as DNI
      let niePrefix = identifier.charAt(0);

      switch (niePrefix) {
        case 'X':
          niePrefix = 0;
          break;
        case 'Y':
          niePrefix = 1;
          break;
        case 'Z':
          niePrefix = 2;
          break;
        default:
          break;
      }

      return this.NIFValidator(niePrefix + identifier.substr(1));
    }
  }
};
